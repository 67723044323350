import React from "react";
import { useForm } from "react-hook-form";
import FieldArray from "./fieldArray";

const defaultValues = {
  test: [
  ]
};

function WishlistForm() {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    setValue
  } = useForm({
    defaultValues
  });
  const onSubmit = (data) => console.log("data", data);

  return (
     <div>
      <FieldArray
        {...{ control, register, defaultValues, getValues, setValue, errors }}
      />
    </div>
    
  );
}

export default WishlistForm
