import React from "react";
import { useFieldArray } from "react-hook-form";

let renderCount = 0;

export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "test"
  });

  renderCount++;

  return (
    <>
      {/* <div className="counter"> Render Count: {renderCount}</div> */}
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id}>
            
              <input class="input is-rounded"
                {...register(`extra_items[]`)}
                placeHolder={item.name}
              />             
              <button type="button" onClick={() => remove(index)}>-</button> 
            
            </li>
          );
        })}
      </ul>
      <button type="button" onClick={() => append({ name: "Gift link" })}>Add Item</button>        
    </>
  );
}
