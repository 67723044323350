import React from "react";
import { useForm } from "react-hook-form";
import GiftItemArray from "./GiftItemArray";



function GiftItemForm({defaultExtraItems}) {
  const defaultValues = {
    extra_items: defaultExtraItems.slice(1).map((item) => ({ name: item }))
  };

  console.log(defaultValues)

  const {
    control,
    register,
    getValues,
    errors,
    setValue
  } = useForm({
    defaultValues
  });
  return (
     <div>
      <GiftItemArray
        {...{ control, register, defaultValues, getValues, setValue, errors }}
      />
    </div>
    
  );
}

export default GiftItemForm
